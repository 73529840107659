import { format, formatDistanceToNow } from 'date-fns';

export function toFriendlyDate(date: number | string | undefined) {
  if (!date) {
    return '-';
  }

  const articleDate = new Date(date);
  const currentTime = new Date();

  const msInADay = 24 * 60 * 60 * 1000;
  const timeDifference = currentTime.getTime() - articleDate.getTime();

  return timeDifference <= msInADay
    ? formatDistanceToNow(articleDate)
    : format(new Date(articleDate), 'MM/dd/yy');
}

//** monthsBack is how many months back you need start and end date for */
export const getPreviousMonthDates = (
  monthsBack = 1,
): {
  startDate: string;
  endDate: string;
} => {
  const today = new Date();
  const previousMonth = new Date(
    today.getFullYear(),
    today.getMonth() - monthsBack,
    1,
  );
  const year = previousMonth.getFullYear();
  const month = String(previousMonth.getMonth() + 1).padStart(2, '0');
  const firstDay = '01';
  const lastDay = new Date(year, previousMonth.getMonth() + 1, 0)
    .getDate()
    .toString();

  return {
    startDate: `${year}-${month}-${firstDay}`,
    endDate: `${year}-${month}-${lastDay}`,
  };
};

const getPreviousQuarterDates = (): { startDate: string; endDate: string } => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  let startMonth: number;
  let endMonth: number;

  if (currentMonth >= 0 && currentMonth <= 2) {
    startMonth = 9;
    endMonth = 11;
  } else if (currentMonth >= 3 && currentMonth <= 5) {
    startMonth = 0;
    endMonth = 2;
  } else if (currentMonth >= 6 && currentMonth <= 8) {
    startMonth = 3;
    endMonth = 5;
  } else {
    startMonth = 6;
    endMonth = 8;
  }

  const startDate = `${currentYear}-${String(startMonth + 1).padStart(
    2,
    '0',
  )}-01`;
  const endDate = `${currentYear}-${String(endMonth + 1).padStart(
    2,
    '0',
  )}-${new Date(currentYear, endMonth + 1, 0).getDate()}`;

  return { startDate, endDate };
};

const getComparePreviousQuarterDates = (): {
  startDate: string;
  endDate: string;
} => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  let startMonth: number;
  let endMonth: number;
  let adjustedYear: number;

  if (currentMonth >= 0 && currentMonth <= 2) {
    startMonth = 6;
    endMonth = 8;
    adjustedYear = currentYear - 1; // Adjust the year for two quarters back
  } else if (currentMonth >= 3 && currentMonth <= 5) {
    startMonth = 9;
    endMonth = 11;
    adjustedYear = currentYear - 1; // Adjust the year for two quarters back
  } else if (currentMonth >= 6 && currentMonth <= 8) {
    startMonth = 0;
    endMonth = 2;
    adjustedYear = currentYear;
  } else {
    startMonth = 3;
    endMonth = 5;
    adjustedYear = currentYear;
  }

  const startDate = `${adjustedYear}-${String(startMonth + 1).padStart(
    2,
    '0',
  )}-01`;
  const endDate = `${adjustedYear}-${String(endMonth + 1).padStart(
    2,
    '0',
  )}-${new Date(adjustedYear, endMonth + 1, 0).getDate()}`;

  return { startDate, endDate };
};

const getPreviousYearDates = (): { startDate: string; endDate: string } => {
  const currentDate = new Date();
  const previousYear = currentDate.getFullYear() - 1;
  const startDate = `${previousYear}-01-01`;
  const endDate = `${previousYear}-12-31`;

  return { startDate, endDate };
};

export const subtractYearFromDates = (dates: {
  startDate: string;
  endDate: string;
}): { startDate: string; endDate: string } => {
  const newStartDate = new Date(dates.startDate);
  const newEndDate = new Date(dates.endDate);

  newStartDate.setFullYear(newStartDate.getFullYear() - 1);
  newEndDate.setFullYear(newEndDate.getFullYear() - 1);

  return {
    startDate: newStartDate.toISOString().split('T')[0],
    endDate: newEndDate.toISOString().split('T')[0],
  };
};

export const getMonthSpan = (startDate: string, endDate: string): number => {
  const start = new Date(startDate);
  const end = new Date(endDate);

  const yearDiff = end.getFullYear() - start.getFullYear();
  const monthDiff = end.getMonth() - start.getMonth();

  return yearDiff * 12 + monthDiff;
};

export const getCustomPeriodDates = (
  startDate: string,
  monthSpan: number,
): { startDate: string; endDate: string } => {
  const adjustedStartDate = new Date(startDate);

  adjustedStartDate.setMonth(adjustedStartDate.getMonth() - monthSpan + 1);
  adjustedStartDate.setDate(1);

  const adjustedEndDate = new Date(startDate);
  const twoDigitMonth = (adjustedStartDate.getMonth() + 1).toLocaleString(
    'en-US',
    { minimumIntegerDigits: 2 },
  );

  adjustedEndDate.setDate(adjustedEndDate.getDate() - 1);

  return {
    startDate: `${adjustedStartDate.getFullYear()}-${twoDigitMonth}-01`,
    endDate: adjustedEndDate.toISOString().split('T')[0],
  };
};

export const getUserTimeZone = (
  zoneFormat: Intl.DateTimeFormatOptions['timeZoneName'] = 'long' as keyof Intl.DateTimeFormatOptions['timeZoneName'],
) => {
  const date = new Date();
  const userTimeZone = Intl.DateTimeFormat(undefined, {
    timeZoneName: zoneFormat,
  }).formatToParts(date);
  const timeZoneName = userTimeZone.find(
    (part) => part.type === 'timeZoneName',
  );

  if (timeZoneName && zoneFormat === 'long') {
    // Remove "Daylight" and "Time" from the string
    const cleanedTimeZoneName = timeZoneName.value
      .replace(/(Daylight|Time)/g, '')
      .trim();

    return cleanedTimeZoneName;
  } else if (timeZoneName) {
    return timeZoneName.value;
  }

  return '';
};

export const comparePreviousQuarterDates = getComparePreviousQuarterDates();
export const previousQuarterDates = getPreviousQuarterDates();
export const previousYearDates = getPreviousYearDates();
