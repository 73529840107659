import { useAuth } from '@on3/ui-lib/src/contexts/AuthProvider';
import clsx from 'clsx';

import styles from './MobileMPU.module.scss';

interface IAdProps {
  id?: string;
  className?: string;
  incontent?: boolean;
}

export const MobileMPU = ({ id, className, incontent = false }: IAdProps) => {
  const { user } = useAuth();

  if (user?.st?.includes('premium')) {
    return null;
  }

  return (
    <div
      className={clsx(styles.ad, incontent && styles.incontent, className)}
      data-ui="ad"
    >
      <div id={id} />
    </div>
  );
};
