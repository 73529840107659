import { SidebarContainer } from 'components/UI/Layout/Containers/Sidebar/SidebarContainer/SidebarContainer';
import { TextLink } from 'components/UI/Link/TextLink';
import { Text } from 'components/UI/Typography/Text';

import styles from './TributeGary.module.scss';

export const TributeGary = () => {
  return (
    <SidebarContainer title={'Garry Paskwietz'}>
      <div className={styles.tributeContainer}>
        <Text className={styles.subHeader} variant="subtitle1">
          1967-2019
        </Text>
        <TextLink
          className={styles.siteName}
          color="primary"
          href={'/teams/usc-trojans/'}
          underline="none"
          variant="h5"
        >
          WeAreSC Founder
        </TextLink>
        <img
          alt="Garry Paskwietz"
          height="161"
          src="https://on3static.com/static/on3/GarryPaskwietz.svg"
          width="260"
        />
      </div>
    </SidebarContainer>
  );
};
