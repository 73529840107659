import type { HeadlineResponse } from '@on3/api';
import { toFriendlyDate } from '@on3/ui-lib/src/utils/date';
import { ResizedImage } from 'components/Image/ResizedImage';
import { TextLink } from 'components/UI/Link/TextLink';
import { Text } from 'components/UI/Typography/Text';

import styles from './ArticleLarge.module.scss';

export const ArticleLarge = ({
  fullUrl,
  featuredImage,
  title,
  author,
  postDate,
  body,
  primaryCategory,
}: HeadlineResponse) => {
  let baseUrl = primaryCategory?.fullUrl;

  if (baseUrl?.endsWith('/')) {
    baseUrl = baseUrl.slice(0, -1);
  }

  return (
    <article className={styles.articleContainer}>
      {featuredImage && (
        <TextLink href={fullUrl || '/'} title={title || 'Article Title'}>
          <ResizedImage
            className={styles.articleImage}
            {...featuredImage}
            fit="cover"
            gravity="0.5x0.5"
            height={338}
            quality={90}
            width={600}
          />
        </TextLink>
      )}
      <div className={styles.articleItemContainer}>
        <div className={styles.articleCategoryWrapper}>
          <TextLink
            color="primary"
            href={`${baseUrl}/news/`}
            underline="none"
            variant="subtitle1"
          >
            {primaryCategory?.name}
          </TextLink>
        </div>
        <TextLink href={fullUrl || '/'} title={title || 'Article title'}>
          <Text className={styles.header} color="primary" component="h2">
            {title}
          </Text>
        </TextLink>
        <Text className={styles.articleBody} variant="body1">
          {body}...
        </Text>
        <div className={styles.articleLinkWrapper}>
          <TextLink
            className={styles.tabFont}
            color="primary"
            href={fullUrl}
            underline="none"
            variant="subtitle1"
          >
            Read Now
          </TextLink>
        </div>

        <div className={styles.articleAuthorContainer}>
          <TextLink
            className={styles.author}
            href={`/user/${author?.niceName}/`}
            variant="caption"
          >
            {author?.name}
          </TextLink>
          <Text variant="caption">
            <span className={styles.articleTimeBullet}>&#8226;</span>
            {toFriendlyDate(postDate)}
          </Text>
        </div>
      </div>
    </article>
  );
};
