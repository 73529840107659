import { Text } from 'components/UI/Typography/Text';

import { SidebarWrapper } from '../SidebarWrapper';
import styles from './SidebarContainer.module.scss';

interface ISidebarContainerProps {
  title?: string;
  titleLineTwo?: string;
  className?: string;
  children: React.ReactNode;
}

export const SidebarContainer = ({
  className,
  title,
  titleLineTwo,
  children,
}: ISidebarContainerProps) => {
  return (
    <SidebarWrapper className={className}>
      {title && (
        <header className={styles.header}>
          <Text
            className={styles.sidebarTitle}
            component="h2"
            variant="overline"
          >
            <span>{title}</span>
            {titleLineTwo && <span>{titleLineTwo}</span>}
          </Text>
        </header>
      )}
      {children}
    </SidebarWrapper>
  );
};
